import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { ListItemText, Divider, ListItemButton } from "@mui/material"
import { promotionalItem } from "../../../utils/specialPromoHelper"
import theme from "../../../theme/mui-theme"

export type ItemProps = {
  text: string
  hide?: boolean
  divider?: boolean
  featured?: boolean
  back?: boolean
  onClick: () => void
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void
  isBold?: boolean
  isExtraOption?: boolean
}

export default function Item({
  text,
  divider,
  featured = false,
  back,
  onClick,
  onKeyDown,
  isBold,
  isExtraOption,
}: ItemProps) {
  function getTextStyle() {
    if (isExtraOption)
      return {
        color: "#82307E",
        fontWeight: 700,
      }
    if (isBold)
      return {
        color: "inherit",
        fontWeight: "700",
      }
    if (featured)
      return {
        color: "#d70000",
        fontWeight: "700",
      }
    if (promotionalItem(text))
      return {
        color: theme.palette.error.main,
        fontWeight: "700",
      }
    // default case
    return {
      color: "inherit",
      fontWeight: "500",
    }
  }

  const textStyle = getTextStyle()

  return (
    <>
      <ListItemButton
        onClick={onClick}
        onKeyDown={onKeyDown}
        sx={{
          padding: 0,
          color: theme.palette.primary.main,
          fontSize: "0.875rem",

          ":hover": { backgroundColor: "inherit" },
        }}
        className="drawer__item"
      >
        {back && <ArrowBackIosIcon className="drawer__arrow" />}
        <ListItemText
          primary={text}
          disableTypography
          style={textStyle}
          sx={{
            marginY: "0.75rem",
          }}
        />
        {back === undefined && !promotionalItem(text) && (
          <ArrowForwardIosIcon className="drawer__arrow" />
        )}
      </ListItemButton>

      {divider && <Divider />}
    </>
  )
}
