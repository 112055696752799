import SearchIcon from "@mui/icons-material/Search"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  styled,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function NoResults({
  searchTerm,
}: {
  searchTerm?: string | null
}) {
  const { schemeType } = useParams()
  const { t, ready } = useTranslation("noResults")

  if (!ready) {
    return null
  }

  const noResultsText = t("noResultsText", {
    returnObjects: true,
    useSuspense: false,
  }) as Array<string>

  return (
    <div>
      {searchTerm ? (
        <>
          <header>
            <Heading>{t("heading")}</Heading>
            <SearchTerm>{`'${searchTerm}'`}</SearchTerm>
          </header>

          <SearchTipsContainer sx={{ color: "primary.main" }}>
            <SearchIconContainer>
              <StyledSearchIcon />
            </SearchIconContainer>
            <h4>{t("searchTips")}</h4>
          </SearchTipsContainer>

          <List>
            {schemeType // LSS has no schemeType
              ? Object.values(noResultsText)
                  .filter((_, index) => ![2, 3, 4].includes(index))
                  .map((text, index) => {
                    return (
                      <SearchTipsListItems text={text} key={`tip-${index}`} />
                    )
                  })
              : Object.values(noResultsText).map((text, index) => {
                  return (
                    <SearchTipsListItems text={text} key={`tip-${index}`} />
                  )
                })}
          </List>
        </>
      ) : (
        <Heading>{t("noSearchTermHeader")}</Heading>
      )}
    </div>
  )
}

const SearchTipsListItems: React.FC<
  React.PropsWithChildren<{ text: string }>
> = ({ text }) => {
  return (
    <ListItem disableGutters disablePadding sx={{ alignItems: "start" }}>
      <Icon>
        <CheckIcon sx={{ color: "success.main" }} />
      </Icon>

      <Typography
        variant="body2"
        sx={{ color: "primary.main", lineHeight: "1.625rem" }}
      >
        {text}
      </Typography>
    </ListItem>
  )
}

const Heading = styled("h4")`
  margin-bottom: 1rem;
`

const SearchTerm = styled("h3")`
  margin-bottom: 2.25rem;
`
const SearchTipsContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 1.25rem;
`

const SearchIconContainer = styled("div")`
  min-width: 2rem;
`

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 1.25rem;
`

const CheckIcon = styled(CheckCircleOutlineIcon)`
  font-size: 1.25rem;
`
const Icon = styled(ListItemIcon)`
  min-width: 2rem;
  padding-top: 0.1875rem;
`
