import { ListItemButton, Link } from "@mui/material"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import { useState } from "react"
import { gtmPortalHomepageNoticeboardButtonClick } from "../gtm-events/CustomGtmEvents"
import theme from "../../../theme/mui-theme"
import { newTabProps } from "../../../data/constants"

export type ItemType = {
  label: string
  link?: string
  newTab?: boolean
  changeUiLanguage?: (newLanguage: "en-GB" | "cy") => void
  external?: boolean
  hide?: boolean
  children?: {
    label: string
    link?: string
  }[]
  triggerGtmEvent?: boolean
  handleMenuToggle?: () => void
}

const Item = ({
  label,
  link,
  changeUiLanguage,
  external = true,
  children,
  triggerGtmEvent,
  handleMenuToggle,
  newTab = false,
}: ItemType) => {
  const [open, setOpen] = useState(false)
  const HandleOpen = () => {
    setOpen(!open)
    triggerGtmEvent && gtmPortalHomepageNoticeboardButtonClick(label)
  }

  const handleLanguageChange = (label: string) => {
    const language = label === "English" ? "en-GB" : "cy"
    changeUiLanguage && changeUiLanguage(language)
    handleMenuToggle && handleMenuToggle()
    // setOpen(!open)
    // confirm what GTM event is triggered for language toggle
    // triggerGtmEvent && gtmPortalHomepageNoticeboardButtonClick(label)
  }

  if (typeof changeUiLanguage == "function") {
    return (
      <ListItemButton
        onClick={() => handleLanguageChange(label)}
        role="menuitem"
        sx={{
          textAlign: "center",
          textDecoration: "none !important",
          justifyContent: "center",
          paddingY: "0.75rem",
          fontSize: "1rem",
          lineHeight: "1.25rem",
          paddingX: "2rem",
          fontWeight: open && children && children.length < 1 ? "bold" : "auto",
          "&:hover": {
            backgroundColor: theme.palette.grey[200],
          },
          "&:first-of-type": {
            borderRadius: "1rem 1rem 0 0 !important",
          },
          "&:last-child": {
            borderRadius: "0 0 1rem 1rem !important",
          },
        }}
      >
        {label}
      </ListItemButton>
    )
  }
  const extraProps = newTab ? newTabProps : {}

  return (
    <>
      <ListItemButton
        component={external ? "a" : Link}
        href={link}
        onClick={HandleOpen}
        role="menuitem"
        {...extraProps}
        sx={{
          textAlign: "center",
          textDecoration: "none !important",
          justifyContent: "center",
          paddingY: "0.75rem",
          fontSize: "1rem",
          lineHeight: "1.25rem",
          paddingX: "2rem",
          fontWeight: open && children && children.length < 1 ? "bold" : "auto",
          "&:hover": {
            backgroundColor: theme.palette.grey[200],
          },
          "&:first-of-type": {
            borderRadius: "1rem 1rem 0 0 !important",
          },
          "&:last-child": {
            borderRadius: "0 0 1rem 1rem !important",
          },
        }}
      >
        {label}
        {children && (
          <KeyboardArrowDown
            sx={{
              mr: -1,
              transform: open ? "rotate(0)" : "rotate(-90deg)",
              transition: "0.2s",
              marginRight: "-27.42px",
            }}
          />
        )}
      </ListItemButton>
      {open &&
        children &&
        children.map((item) => (
          <ListItemButton
            component={"a"}
            href={item.link}
            key={item.label}
            sx={{
              textAlign: "center",
              textDecoration: "none !important",
              justifyContent: "center",
              paddingY: "0.75rem",
              fontSize: "1rem",
              lineHeight: "1.25rem",
              paddingX: "2rem",
              "&:hover": {
                backgroundColor: theme.palette.grey[200],
              },
            }}
          >
            {item.label}
          </ListItemButton>
        ))}
    </>
  )
}

export default Item
