import { Outlet } from "react-router-dom"
import Header from "../layout/header/Header"
import Footer from "../layout/footer/Footer"
import {
  ClientAdminLayoutContext,
  useClientAdminLayoutData,
} from "./ClientAdminLayoutContext"
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs"
import { AdminBreadcrumbContainer } from "../benefit-managment/BenefitManagement"
import { useClientAdminBreadcrumb } from "./ClientAdminBreadcrumb" // Adjust the path as needed
import { useEffect, useState } from "react"
import Modal from "../shared/Modal"
import { Button, Typography } from "@mui/material"
import { newTabProps } from "../../data/constants"

const ClientAdminLayout = ({
  setShouldShowCookiePopup,
}: {
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const contextValue = useClientAdminLayoutData()
  const breadcrumbsItems = useClientAdminBreadcrumb()
  const [showInstructions, setShowInstructions] = useState(false)

  useEffect(() => {
    const seenUserIds = JSON.parse(
      localStorage.getItem("seenAdminInstructions") || "[]"
    )
    if (
      contextValue.currentUserId &&
      !seenUserIds.includes(contextValue.currentUserId)
    ) {
      setShowInstructions(true)
    }
  }, [contextValue.currentUserId])

  const handleClose = () => {
    localStorage.setItem(
      "seenAdminInstructions",
      JSON.stringify([
        ...JSON.parse(localStorage.getItem("seenAdminInstructions") || "[]"),
        contextValue.currentUserId,
      ])
    )

    setShowInstructions(false)
  }

  const guideLink =
    contextValue.commsHubLink ||
    "https://vivup-production-assets.s3.eu-west-2.amazonaws.com/en-GB/Benefit+Management+User+Guide.pdf"

  return (
    <ClientAdminLayoutContext.Provider value={contextValue}>
      <Header
        showBasket={false}
        showNoticeboard={[]}
        clientAdminLayout={true}
        backendUser={contextValue.backendUser}
        isOrganisationSettingUser={contextValue.isOrganisationSettingUser}
        reporter={contextValue.reporter}
        currentUserId={contextValue.currentUserId}
        employeeOrganisations={contextValue.organisations}
        commsHubLink={contextValue.commsHubLink}
      />

      <div
        className="static-main-container static-main-container--gray"
        id="main-content"
      >
        <AdminBreadcrumbContainer>
          <CustomBreadcrumbs breadcrumbsItems={breadcrumbsItems} />
        </AdminBreadcrumbContainer>
        <Outlet />
      </div>
      <Footer
        privacyNoticeUrl={contextValue.customPrivacyNoticeUrl}
        organisationPrivacyNotice={contextValue.organisationPrivacyNotice}
        setShouldShowCookiePopup={setShouldShowCookiePopup}
      />
      <Modal open={showInstructions} handleClose={handleClose}>
        <>
          <Typography
            variant="body1"
            color="primary"
            sx={{ marginBottom: "1rem" }}
          >
            <p>
              We have made some changes to the look and feel of the Benefit
              Management area of your portal. These changes make it easier than
              before for you to add, edit and navigate.
            </p>
            {contextValue.commsHubLink ? (
              <p>
                Start exploring or read our handy user guide located in your{" "}
                <a
                  href={guideLink}
                  {...newTabProps}
                  style={{ textDecoration: "underline" }}
                >
                  CommsHub
                </a>{" "}
                for more information
              </p>
            ) : (
              <p>
                Start exploring or read our handy{" "}
                <a
                  href={guideLink}
                  {...newTabProps}
                  style={{ textDecoration: "underline" }}
                >
                  user guide
                </a>{" "}
                for more information
              </p>
            )}
          </Typography>
          <Button variant="gradient" onClick={handleClose}>
            Start exploring
          </Button>
        </>
      </Modal>
    </ClientAdminLayoutContext.Provider>
  )
}

export default ClientAdminLayout
